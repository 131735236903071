<template>
  <div class="text-center">
    <transition name="fade">
      <div class="__loader">
        <i class="__loader-icon fa fa-spin fa-refresh" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="sass" scoped>
.k-card
  position: relative
  .__loader
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(255, 255, 255, 0.6)
    z-index: 999
    pointer-events: none
  .__loader-icon
    display: block
    font-size: 40px
  .__filters
    margin: 20px 0
  .__button-legend
    margin-bottom: 0
    height: 24px
  .__search-btn
    color: #fff
    margin-top: 0
    &:hover
      color: #fff
  .page-link
    margin: 0 2px
    &.disabled
      opacity: 0.6
      pointer-events: none
    &.active
      pointer-events: none
</style>